<template>
  <BModal
    :id="config.id"
    :modal-class="config.class"
    :no-close-on-backdrop="config.noCloseOnBackdrop"
    :hide-header="config.hideHeader"
    :centered="config.centered"
    :hide-footer="config.hideFooter"
    :fullscreen="config.fullscreen"
    :scrollable="config.scrollable"
    @hide="hideModal"
    @show="goBack"
  >
    <div v-if="config.isBack" class="modal-back">
      <div class="modal-back__icon" @click="goBack">
        <span class="icon-arrow-left" />
      </div>
      <span @click="goBack">{{ config.textBack }}</span>
    </div>
    <div v-if="config.title" class="modal-title">
      <span>{{ config.title }}</span>
    </div>
    <div v-if="!config.hideClose" class="modal-close" @click="hideModal()">
      <CommonBaseImg :src="'/assets/images/icon/icon-close.svg'" alt="loading" />
    </div>
    <div class="modal-slot">
      <slot name="content" />
    </div>
  </BModal>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import { ModalConfig } from '~/types/modal.type'
import CommonBaseImg from '~/components/common/base-img.vue'
const props = defineProps({
  config: {
    type: Object as PropType<ModalConfig>,
    default: () => ({})
  },
  defaultConfig: {
    type: Object as PropType<ModalConfig>,
    default(): ModalConfig {
      return {
        id: '',
        class: null,
        isBack: false,
        noCloseOnBackdrop: true,
        centered: true,
        hideHeader: true,
        hideFooter: true,
        hideClose: false,
        fullscreen: true,
        iconClose: '',
        textBack: '',
        scrollable: false
      }
    }
  }
})

const config: ModalConfig = { ...props.defaultConfig, ...props.config }

const { closeModal } = useModal()
const route = useRoute()
const emit = defineEmits(['close', 'go-back'])

const hideModal = () => {
  if (route?.query?.popup) {
    const query = { ...route.query }
    delete query.popup
    navigateTo({
      query: { ...query }
    })
  }
  closeModal(props.config.id)
  emit('close')
}

const goBack = () => {
  emit('go-back')
}
</script>

<style lang="scss" scoped src="assets/scss/components/common/base-modal.scss"></style>
